import request from '../utils/request'


//添加部门
export function queryshorlink(params) {
    return request({
        url: "/api/shortlink/short_link/queryshorlink",
        method: 'post',
        data: params
    })
}